define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/c8275de1b001415473701f3f9982f6f0fd2de765/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/page/request"], function (declare, request) {
  return declare([], {
    constructor: function (params) {
      document.addEventListener('click', function (e) {
        if (e.target.tagName !== 'A') {
          return;
        }
        request.get(params.endpoint + "&url=" + encodeURIComponent(e.target.href));
      }, false);
    }
  });
});